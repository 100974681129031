<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('门名称')" prop="doorName">
                <a-input v-model="queryParam.doorName" :placeholder="$t('请输入门名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('4G卡到期状态')" prop="status">
                <a-select v-model="queryParam.status" :placeholder="$t('请选择到期状态')" allow-clear>
                  <a-select-option :value="0">{{$t('未到期')}}</a-select-option>
                  <a-select-option :value="1">{{$t('已到期')}}</a-select-option>
                  <a-select-option :value="2">{{$t('即将到期')}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('设备编码')" prop="devCode">
                  <a-input v-model="queryParam.devCode" :placeholder="$t('请输入设备编码')" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('重置')}}</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['door:device:add']">
          <a-icon type="plus" />新增
        </a-button> -->
        <!-- <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['door:device:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['door:device:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        :row-class-name="getRowClassName"
      >
       <!---图片显示--->
     <span slot="pic" slot-scope="text, record">
          <img style="width:50px;heigth:50px" :src="record.avatar" />
        </span>

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['door:device:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['door:device:edit']">
            <a-icon type="edit" />{{$t('修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['door:device:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['door:device:remove']" v-if="record.online==0">
            <a-icon type="delete" />{{$t('删除')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => $t('共') + total + $t('条')"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDevice,listDevice, delDevice } from '@/api/door/device'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import moment from 'moment'

export default {
  name: 'Device',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        doorName: null,
        doorType: null,
        devCode: null,
        onlineTime: null,
        offTime: null,
        online: null,
        devIccId: null,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
      {
          title: this.$t('公司id'),
          dataIndex: 'companyId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('门名称'),
          dataIndex: 'doorName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('门类型'),
          dataIndex: 'doorTypeStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('设备编码'),
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('在线时间'),
          dataIndex: 'onlineTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('离线时间'),
          dataIndex: 'offTime',
          ellipsis: true,
          align: 'center'
        },
         {
          title: this.$t('ICCID'),
          dataIndex: 'devIccId',
          ellipsis: true,
          align: 'center'
        },
         {
          title: this.$t('到期时间'),
          dataIndex: 'stopTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('使用时间（开始）'),
          dataIndex: 'startTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('使用时间（结束）'),
          dataIndex: 'endTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('是否在线'),
          dataIndex: 'onlineStr',
          ellipsis: true,
          align: 'center'
        },
        
        // {
        //   title: this.$t('上升logo'),
        //   dataIndex: 'upLogo',
        //   scopedSlots: { customRender: 'pic' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        
        // {
        //   title: this.$t('下降logo'),
        //   dataIndex: 'downLogo',
        //   scopedSlots: { customRender: 'pic' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        
        // {
        //   title: this.$t('中止logo'),
        //   dataIndex: 'waitLogo',
        //   scopedSlots: { customRender: 'pic' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('在线logo'),
          dataIndex: 'onlineLogo',
          scopedSlots: { customRender: 'pic' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('离线logo'),
          dataIndex: 'offLogo',
          scopedSlots: { customRender: 'pic' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('添加时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询设备信息列表 */
    getList () {
      this.loading = true
     pageDevice(this.queryParam).then(response => {
        this.list = response.data.records
        this.list.forEach(element => {
          if(0==element.online){
            element.onlineStr=this.$t('离线')
          }else{
            element.onlineStr=this.$t('在线')
          }
          if(0==element.doorType){
            element.doorTypeStr=this.$t('自动门')
          }else{
            element.doorTypeStr=this.$t('工业门')
          }
        });
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        doorName: undefined,
        doorType: undefined,
        devCode: undefined,
        onlineTime: undefined,
        offTime: undefined,
        online: undefined,
        devIccId: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('确认删除所选中数据?'),
        content: this.$t('当前选中编号为') + ids + this.$t('的数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delDevice(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                this.$t('删除成功'),
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('是否确认导出?'),
        content: this.$t('此操作将导出当前条件下所有数据而非选中数据')  ,
        onOk () {
          that.download('door/device/export', {
            ...that.queryParam
          }, `设备信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    getRowClassName(record) {
      if (record.stopTime) {
        const stopTime = moment(record.stopTime)
        const now = moment()
        const days = stopTime.diff(now, 'days')
        
        if (days < 0) {
          // 已到期
          return 'expired-row'
        } else if (days >= 0 && days <= 30) {
          // 即将到期
          return 'warning-row'
        }
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.warning-row) {
  background-color: #fff1f0;
  &:hover > td {
    background-color: #ffccc7 !important;
  }
}

:deep(.expired-row) {
  background-color: #f5f5f5;
  &:hover > td {
    background-color: #e8e8e8 !important;
  }
}
</style>
